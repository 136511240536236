/* src/app/components/switch/switch.component.scss */
:host ::ng-deep .mdc-switch:enabled .mdc-switch__track::after {
  background: #6a80af !important;
}
:host ::ng-deep .mdc-switch:enabled .mdc-switch__shadow {
  background: #304269 !important;
}
:host ::ng-deep .mdc-switch:enabled .mdc-switch__track::before {
  background: #6a80af !important;
}
:host ::ng-deep .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: none !important;
}
:host ::ng-deep .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  fill: none !important;
}
:host ::ng-deep .mat-mdc-slide-toggle .mdc-switch:enabled + .mdc-label {
  font-size: 18px !important;
}
/*# sourceMappingURL=switch.component-ANN7IGSG.css.map */
