<div class="w-100 relative vertical-center">
  <div class="center w-xs-90">
    <div role="search" class="search-wrapper">
      <button
        mat-button
        class="no-click category-button"
        (click)="drawer.toggle()"
      >
        <span>Todas las categorías</span>
      </button>
      <div class="input-container">
        <input
          #searchInputFull
          class="search-input"
          [formControl]="criteria"
          name="search"
          type="text"
          placeholder="Buscar productos, marcas y más"
          (keyup)="searchProduct($event.target.value)"
          autocomplete="off"
        />
        <mat-icon
          class="btn-search"
          (click)="focus(searchInputFull)"
          aria-label="Go to login"
        >
          search
        </mat-icon>
      </div>
    </div>

    <mat-autocomplete #auto="matAutocomplete">
      <ng-container *ngIf="findsFiltered | async as xFindsFiltered">
        <ng-container
          *ngIf="xFindsFiltered.searchCriteriasRelatedByUser as relatedByUser"
        >
          <mat-option
            (click)="searchByFind(find.Criteria)"
            *ngFor="let find of relatedByUser"
            [value]="find.Criteria"
            class="relative"
          >
            <mat-icon class="auto-icon">access_time</mat-icon
            >{{ find.Criteria }}
            <button
              mat-icon-button
              (click)="removeSearchCriteria($event, find.FK_SearchCriteriaUser)"
            >
              <mat-icon class="delete-option m-0"> delete</mat-icon>
            </button>
          </mat-option>
        </ng-container>
        <ng-container *ngIf="xFindsFiltered.searchCriteriasRelated as related">
          <mat-option
            (click)="searchByFind(find.Criteria)"
            *ngFor="let find of related"
            [value]="find.Criteria"
          >
            <mat-icon class="auto-icon">search</mat-icon>{{ find.Criteria }}
          </mat-option>
        </ng-container>
        <mat-option
          (click)="searchByTag(tag, false)"
          *ngFor="let tag of tagsFiltered | async"
          [value]="tag.Value"
        >
          <mat-icon class="auto-icon"><i class="fas fa-hashtag"></i></mat-icon
          >{{ tag.Value }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </div>
</div>